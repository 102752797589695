@font-face {
  font-family: "Epilogue";
  src: local("Epilogue"),
  url("./assets/fonts/Epilogue-Regular.ttf") format("truetype");
  font-weight: regular;
}

@font-face {
  font-family: "EpilogueBold";
  src: local("EpilogueBold"),
  url("./assets/fonts/Epilogue-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "EpilogueLight";
  src: local("EpilogueLight"),
  url("./assets/fonts/Epilogue-Light.ttf") format("truetype");
  font-weight: light;
}

@font-face {
  font-family: "MyriadPro";
  src: local("MyriadPro"),
  url("./assets/fonts/MyriadPro/MYRIADPRO-REGULAR.OTF") format("opentype");
}

@font-face {
  font-family: "MyriadProSemiBold";
  src: local("MyriadPro"),
  url("./assets/fonts/MyriadPro/MYRIADPRO-SEMIBOLD.OTF") format("opentype");
}

@font-face {
  font-family: "MyriadProLight";
  src: local("MyriadPro"),
  url("./assets/fonts/MyriadPro/MyriadPro-Light.otf") format("opentype");
}

/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.not-allowed {
  cursor: not-allowed;
}
.input-icons i {
  position: absolute;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
}

.custom-card {
  transition: all .25s linear;
  box-shadow: 0px 1px 2px 0px rgba(237, 11, 37,0.4);
}

.custom-card:hover{
  box-shadow: -1px 10px 29px 0px rgba(237, 11, 37,0.8);
}
.icon {
  padding: 10px;
  color: green;
  min-width: 50px;
  text-align: center;
}

.input-field {
  width: 100%;
  padding: 10px;
  text-align: center;
}
.fade-in-text {
  display: inline-block;
  animation: fadeIn linear 1s;
  -webkit-animation: fadeIn linear 1s;
  -moz-animation: fadeIn linear 1s;
  -o-animation: fadeIn linear 1s;
  -ms-animation: fadeIn linear 1s;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
.neonText {
  text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #ed0b25,
    0 0 82px #ed0b25,
    0 0 92px #ed0b25,
    0 0 102px #ed0b25,
    0 0 151px #ed0b25;
}*/